<template>
  <q-toolbar class="q-py-sm bg-white" style="height: 64px">
    <h6 class="q-ma-none q-pa-sm">Händelser</h6>

    <q-btn
      v-if="can('deviationManagement.event.create')"
      class="q-mr-sm"
      unelevated
      color="primary"
      label="Lägg till"
      @click="() => formModal.openModal({ mode: 'create', data: null })"
      icon-right="mdi-plus-circle-outline"
    />

    <q-space />

    <div class="col q-px-sm" style="max-width: 400px">
      <q-input
        clearable
        bg-color="white"
        dense
        square
        outlined
        v-model="filterTextValue"
        placeholder="Sök..."
      >
        <template v-slot:prepend>
          <q-icon name="mdi-magnify" />
        </template>
      </q-input>
    </div>

    <q-space />

    <q-btn-dropdown
      label="Filtera på skapad av roll"
      class="q-mr-md"
      v-if="project === 'gotalandstag'"
      outline
      color="grey-7"
    >
      <template #label>
        <q-badge color="primary" floating>
          {{ visibleRoleFilterCount }}
        </q-badge>
      </template>
      <q-list dense>
        <q-item tag="label" @mousedown.stop.prevent="onShowAllRoles">
          <q-item-section avatar>
            <q-checkbox :model-value="showAllRoles" />
          </q-item-section>

          <q-item-section>
            <q-item-label>Visa alla</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator />
        <q-item
          tag="label"
          clickable
          v-for="(col, $i) of uniqueRoles"
          :key="$i"
        >
          <q-item-section avatar>
            <q-checkbox v-model="col.visible" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ col.label }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>

    <q-btn
      outline
      color="grey-7"
      :label="`${tempPeriod.from} - ${tempPeriod.to}`"
      icon-right="mdi-calendar-range"
      class="q-mr-md"
    >
      <q-menu v-model="showPeriodPicker">
        <q-card>
          <q-card-section>
            <div class="row q-col-gutter-md">
              <div class="col-6">
                <q-input
                  label="Från"
                  type="date"
                  dense
                  flat
                  outlined
                  v-model="tempPeriod.from"
                  error-message="Måste vara före slutdatum"
                />
              </div>
              <div class="col-6">
                <q-input
                  label="Till"
                  type="date"
                  dense
                  flat
                  outlined
                  v-model="tempPeriod.to"
                />
              </div>
            </div>
            <div class="row text-red text-caption" v-if="!validDate">
              Datum från måste börja innan datum till.
            </div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              color="primary"
              label="Hämta"
              :loading="loading.getAll"
              :disable="!validDate"
              @click="fetchEvents"
            />
          </q-card-actions>
        </q-card>
      </q-menu>
    </q-btn>

    <q-btn-group outline>
      <q-btn
        outline
        color="grey-7"
        label="Filtrera"
        icon-right="mdi-filter-menu-outline"
      >
        <EventFilterModal />
        <q-badge color="primary" floating v-if="!!isFilterActiveCount">
          {{ isFilterActiveCount }}
        </q-badge>
      </q-btn>

      <q-btn
        outline
        color="grey-7"
        label="Exportera"
        @click="onExport"
        :loading="exporting"
        icon-right="mdi-file-export-outline"
      />
    </q-btn-group>
  </q-toolbar>
  <q-separator />
</template>

<script lang="ts">
import { defineComponent, computed, inject, ref, PropType, watch } from 'vue'
import { UseModal } from '@/composable/useModal'
import { Event } from '@/types/event'
import { exportExcel } from '@/services/export-excel'
import { useEvent } from '@/composable/useEvent'
import { QTable } from 'quasar'
import EventFilterModal from './EventFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'
import { isAfter } from 'date-fns'
import isEqual from 'lodash.isequal'
import { useUserSettings } from '@/composable/useUserSettings'

export default defineComponent({
  name: 'EventTableToolbar',

  components: {
    EventFilterModal,
  },

  emits: ['update:filterText'],

  props: {
    filterText: String,
    columns: Array as PropType<QTable['columns']>,
  },

  setup(props, { emit }) {
    const { can, currentProject } = useProfile()
    const formModal = inject<UseModal<Event>>('form-modal')
    const { period, fetchAll, loading } = useEvent()
    const filterTextValue = computed({
      get: () => props.filterText,
      set: (value) => emit('update:filterText', value),
    })
    const exporting = ref(false)

    const { settings } = useUserSettings()

    const showAllRoles = ref(true)

    const { list: events, uniqueRoles } = useEvent()
    const project = currentProject.value?.name as string
    const isFilterActiveCount = computed(() => {
      return [
        settings.value[project].event.filterPenaltyAssessment,
        settings.value[project].event.filterAutoDelay,
        settings.value[project].event.filterDeleted,
      ].filter(Boolean).length
    })

    watch(
      () => events.value,
      () => {
        uniqueRoles.value = uniqueRoles.value.map((x) => {
          return {
            visible: settings.value[project].event.roles
              ? settings.value[project].event.roles.includes(x.label)
              : true,
            label: x.label,
          }
        })
      }
    )

    watch(
      () => uniqueRoles.value,
      (list) => {
        settings.value[project].event.roles = list
          .filter((x) => x.visible)
          .map((x) => x.label)

        if (settings.value[project].event.roles.length === list.length) {
          showAllRoles.value = true
        } else {
          showAllRoles.value = false
        }
      },
      {
        deep: true,
      }
    )

    const onShowAllRoles = () => {
      showAllRoles.value = !showAllRoles.value

      uniqueRoles.value = uniqueRoles.value.map((x) => ({
        ...x,
        visible: showAllRoles.value,
      }))
    }

    const visibleRoleFilterCount = computed(() => {
      return uniqueRoles.value.filter((x) => x.visible).length
    })

    async function onExport() {
      exporting.value = true

      const exportData = buildExcelSheetFromTable({
        name: 'Händelser',
        table: events.value,
        columns: props.columns,
      })

      exportExcel(exportData)

      exporting.value = false
    }
    const showPeriodPicker = ref(false)

    const validDate = computed(() => {
      return (
        isAfter(
          new Date(tempPeriod.value.to),
          new Date(tempPeriod.value.from)
        ) ||
        isEqual(new Date(tempPeriod.value.to), new Date(tempPeriod.value.from))
      )
    })

    const tempPeriod = ref({
      from: period.value.from,
      to: period.value.to,
    })

    const fetchEvents = async () => {
      period.value.from = tempPeriod.value.from
      period.value.to = tempPeriod.value.to
      await fetchAll(period.value)
      showPeriodPicker.value = false
    }

    return {
      filterTextValue,
      formModal,
      onExport,
      exporting,
      can,
      period,
      isFilterActiveCount,
      validDate,
      fetchEvents,
      tempPeriod,
      showPeriodPicker,
      loading,
      uniqueRoles,
      visibleRoleFilterCount,
      showAllRoles,
      onShowAllRoles,
      project,
    }
  },
})
</script>

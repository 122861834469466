
import { computed, defineComponent, ref } from 'vue'
import { useEvent } from '@/composable/useEvent'
import QForm from 'quasar/src/components/form/QForm.js';
import { isAfter, isEqual } from 'date-fns'
import { useUserSettings } from '@/composable/useUserSettings'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'EventFilterModal',

  components: {},

  setup() {
    const formRef = ref<QForm | null>(null)
    const { period, loading } = useEvent()
    const { currentProject } = useProfile()
    const project = currentProject.value?.name as string
    const { settings } = useUserSettings()

    const showFilterMenu = ref(false)

    const validDate = computed(() => {
      return (
        isAfter(new Date(period.value.to), new Date(period.value.from)) ||
        isEqual(new Date(period.value.to), new Date(period.value.from))
      )
    })

    return {
      showFilterMenu,
      loading,
      validDate,
      formRef,
      period,
      settings,
      project,
    }
  },
})


import { computed, defineComponent, inject, PropType, ref } from 'vue'
import QTable from 'quasar/src/components/table/QTable.js';
import { useEvent } from '@/composable/useEvent'
import { EventTableItem } from '@/types/event'
import orderBy from 'lodash.orderby'
import { routerPush } from '@/router'
import EventRowActionsDropdown from './EventRowActionsDropdown.vue'
import { UseModal } from '@/composable/useModal'
import { CausingVehicle } from '@/types/causing-vehicle'
import { getVehicleDefect } from '@/api/vehicle-defect/getVehicleDefect'
import { Log } from '@/types/log'
import { useProfile } from '@/composable/useProfile'
import { useVehicle } from '@/composable/useVehicle'
import { useXod } from '@/composable/useXod'
import { useUserSettings } from '@/composable/useUserSettings'

export default defineComponent({
  name: 'EventTable',

  components: {
    EventRowActionsDropdown,
  },

  props: {
    filterText: String,
    columns: Array as PropType<QTable['columns']>,
  },

  setup() {
    const { data: xodData, loading: loadingXod, search: searchXod } = useXod()
    const eventTableRef = ref<QTable | null>(null)
    const fordModal = inject<UseModal<Log[]>>('ford-modal')
    const { pagination, uniqueRoles } = useEvent()
    const { can, currentProject } = useProfile()
    const { settings } = useUserSettings()
    const project = currentProject.value?.name as string
    const { list: events, loading: loadingStateEvents } = useEvent(true)

    const {
      fetchAll: getAllVehicles,
      data: vehicles,
      loading: loadingStateVehicles,
    } = useVehicle()

    getAllVehicles()

    type SortMethod<T> = (rows: T, sortBy: string, desc: boolean) => T

    const sortMethod: SortMethod<EventTableItem[]> = (rows, sortBy, desc) => {
      const sortedRows: EventTableItem[] = orderBy(
        rows,
        [sortBy, 'created'],
        ['desc', desc ? 'desc' : 'asc', 'desc']
      )

      dateGroups.value = sortedRows.reduce<Acc>((acc, row, i) => {
        if (!(row.date in acc)) {
          acc[row.date] = i
        }

        return acc
      }, {})

      return sortedRows
    }

    const warnings = (row: EventTableItem) => {
      const _warnigns: string[] = []

      if (
        row.reason1 === 'Fordonsskada' &&
        (!row.causingVehicles?.length ||
          !row.causingVehicles?.every((x) => x.vehicleUuid))
      ) {
        _warnigns.push(`Saknar orsakande fordon`)
      }

      if (
        row.reason1 === 'Fordonsskada' &&
        (!row.causingVehicles?.length ||
          !row.causingVehicles?.every((x) => x.ford))
      ) {
        _warnigns.push(`Saknar skadenummer FORD på orsakande fordon`)
      }

      return _warnigns
    }

    const sortedEvents = computed(() => {
      const filteredEvents = events.value
        .map((event) => ({
          ...event,
          _warnings: warnings(event),
          _deviationCount:
            event.deviationCancelCount +
            event.deviationCrewCount +
            event.deviationDelayCount +
            event.deviationDisruptionCount +
            event.deviationVehicleCount,
          causingVehiclesNames: (event.causingVehicles || [])
            .map((causingVehicle) => {
              return (
                vehicles.value.find(
                  (vehicle) => vehicle.uuid === causingVehicle.vehicleUuid
                )?.name || '-'
              )
            })
            .join(', '),
        }))
        .filter((x) =>
          settings.value[project].event.roles && project === 'gotalandstag'
            ? uniqueRoles.value.length ===
                settings.value[project].event.roles.length ||
              x.createdByRoles.some((c) =>
                settings.value[project].event.roles.includes(c)
              )
            : true
        )
        .filter((x) =>
          settings.value[project].event.filterDeleted ? x?.deleted : !x?.deleted
        )
        .filter((x) =>
          settings.value[project].event.filterPenaltyAssessment
            ? x.reason1 === 'Fordonsskada' && x.penaltyAssessment === null
            : true
        )
        .filter((x) =>
          settings.value[project].event.filterAutoDelay
            ? true
            : !x.eventFlags.some((x) => x.flag === 'AUTO_DELAY')
        )
      return orderBy(filteredEvents, ['open', 'created'], ['asc', 'desc'])
    })

    type Acc = {
      [datum: string]: number
    }

    const dateGroups = ref<Acc>({})

    function onEventRowClick(e: Event, event: EventTableItem) {
      routerPush('event', { eventUuid: event.uuid })
    }

    function onClickFordChip(causingVehicle: CausingVehicle) {
      fordModal?.openModal({
        cb: async (setData) => {
          const defectNumber = causingVehicle.ford as number
          await getVehicleDefect(defectNumber)
            .then(({ data }) => {
              setData(data)
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const loading = computed(() => {
      return (
        loadingStateVehicles.value.getAll || loadingStateEvents.value.getAll
      )
    })

    const xodColumnValue = (xodId: number) => {
      const xod = xodData.value.find((x) => xodId === x.trafficDisturbanceId)
      if (xod) {
        return xod.title
      }

      return 'Xod titel hittades inte'
    }

    function hasAutoDelay(row: { eventFlags?: { flag: string }[] }) {
      if (
        row.eventFlags?.some((x) => {
          return x.flag === 'AUTO_DELAY'
        })
      ) {
        return true
      }
      return false
    }

    return {
      can,
      sortedEvents,
      dateGroups,
      loading,
      pagination,
      sortMethod,
      onEventRowClick,
      fordModal,
      onClickFordChip,
      eventTableRef,
      searchXod,
      loadingXod,
      xodColumnValue,
      hasAutoDelay,
    }
  },
})

import { render } from "./EventFilterModal.vue?vue&type=template&id=fb2c4736"
import script from "./EventFilterModal.vue?vue&type=script&lang=ts"
export * from "./EventFilterModal.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QMenu,QToggle});

import { render } from "./EventTable.vue?vue&type=template&id=361529e2"
import script from "./EventTable.vue?vue&type=script&lang=ts"
export * from "./EventTable.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./EventTable.vue?vue&type=style&index=0&id=361529e2&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTd,QBtn,QBadge,QIcon,QTooltip,QSeparator,QSpinnerDots});

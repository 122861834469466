<template>
  <q-table
    ref="eventTableRef"
    :class="$style.stickyHeader"
    dense
    wrap-cells
    :loading="loading.getAll"
    :filter="filterText"
    :visible-columns="[columns.filter((x) => x.required).map((x) => x.name)]"
    :rows-per-page-options="[0]"
    v-model:pagination="pagination"
    :rows="sortedEvents"
    :columns="columns"
    row-key="uuid"
    binary-state-sort
    @row-click="onEventRowClick"
    flat
  >
    <template v-slot:body-cell-causingVehicles="props">
      <q-td :props="props">
        <template v-if="props.value && props.value.length">
          <template
            v-for="causingVehicle in props.value"
            :key="causingVehicle.id"
          >
            <q-btn
              v-if="causingVehicle.ford"
              outline
              dense
              size="12px"
              class="q-mb-xs"
              :disable="!can('deviationManagement.vehicleDefect.list')"
              @click.stop="onClickFordChip(causingVehicle)"
            >
              {{ causingVehicle.ford }}
            </q-btn>
          </template>
        </template>
      </q-td>
    </template>

    <template v-slot:body-cell-open="props">
      <q-td :props="props">
        <q-badge rounded v-if="props.value" color="positive" />
      </q-td>
    </template>

    <template v-slot:body-cell-_warnings="props">
      <q-td :props="props">
        <q-icon
          color="negative"
          name="mdi-alert"
          v-if="props.row._warnings.length"
        >
          <q-tooltip>
            <template v-for="(warning, $i) in props.row._warnings" :key="$i">
              {{ warning }}<br />
            </template>
          </q-tooltip>
        </q-icon>
      </q-td>
    </template>

    <template v-slot:body-cell-_event_type="props">
      <q-td :props="props">
        <q-badge outline color="grey-6" style="padding: 0">
          <div class="column">
            <div class="flex align-center justify-center">
              <span
                style="flex: 1 1 0; border-radius-top: 2px"
                class="
                  flex
                  justify-center
                  text-weight-bold
                  q-px-xs
                  text-white
                  bg-grey-6
                "
                >{{
                  props.row.deviationCancelCount +
                  props.row.deviationCrewCount +
                  props.row.deviationVehicleCount +
                  props.row.deviationDelayCount
                }}</span
              >
            </div>
            <div class="flex">
              <span
                :class="[
                  props.row.deviationCancelCount && $style.eventTypeTextColor1,
                  'text-weight-bold q-px-xs',
                ]"
                >{{ props.row.deviationCancelCount }}</span
              >
              <q-separator vertical color="grey-6" />
              <span
                :class="[
                  props.row.deviationVehicleCount && $style.eventTypeTextColor2,
                  'text-weight-bold q-px-xs',
                ]"
                >{{ props.row.deviationVehicleCount }}</span
              >
              <q-separator vertical color="grey-6" />
              <span
                :class="[
                  props.row.deviationCrewCount && $style.eventTypeTextColor3,
                  'text-weight-bold q-px-xs',
                ]"
                >{{ props.row.deviationCrewCount }}</span
              >
              <q-separator vertical color="grey-6" />
              <span
                :class="[
                  props.row.deviationDelayCount && $style.eventTypeTextColor4,
                  'text-weight-bold q-px-xs',
                ]"
                >{{ props.row.deviationDelayCount }}</span
              >
            </div>
          </div>
          <q-tooltip>
            Totalt
            {{
              props.row.deviationCancelCount +
              props.row.deviationCrewCount +
              props.row.deviationVehicleCount
            }}
            <br />
            Inställda tåg: {{ props.row.deviationCancelCount }}
            <br />
            Fordonsavvikelser: {{ props.row.deviationVehicleCount }}
            <br />
            Bemanningsavvikelser {{ props.row.deviationCrewCount }}
            <br />
            Försenade tåg {{ props.row.deviationDelayCount }}
          </q-tooltip>
        </q-badge>
      </q-td>
    </template>

    <template v-slot:body-cell-xod="props">
      <q-td :props="props">
        <q-btn
          outline
          dense
          size="12px"
          class="q-mb-xs"
          v-if="props.value"
          @click.stop
        >
          {{ props.value }}
          <q-tooltip @before-show="() => searchXod(props.value)">
            <transition
              enter-active-class="animated animate__fadeIn"
              leave-active-class="animated animate__fadeOut"
              mode="out-in"
              :duration="140"
            >
              <template v-if="loadingXod">
                <q-spinner-dots color="white" size="2em" />
              </template>
              <template v-else>
                {{ xodColumnValue(props.value) }}
              </template>
            </transition>
          </q-tooltip>
        </q-btn>
      </q-td>
    </template>

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <EventRowActionsDropdown :event="props.row" />
      </q-td>
    </template>
  </q-table>
</template>

<script lang="ts">
import { computed, defineComponent, inject, PropType, ref } from 'vue'
import { QTable } from 'quasar'
import { useEvent } from '@/composable/useEvent'
import { EventTableItem } from '@/types/event'
import orderBy from 'lodash.orderby'
import { routerPush } from '@/router'
import EventRowActionsDropdown from './EventRowActionsDropdown.vue'
import { UseModal } from '@/composable/useModal'
import { CausingVehicle } from '@/types/causing-vehicle'
import { getVehicleDefect } from '@/api/vehicle-defect/getVehicleDefect'
import { Log } from '@/types/log'
import { useProfile } from '@/composable/useProfile'
import { useVehicle } from '@/composable/useVehicle'
import { useXod } from '@/composable/useXod'
import { useUserSettings } from '@/composable/useUserSettings'

export default defineComponent({
  name: 'EventTable',

  components: {
    EventRowActionsDropdown,
  },

  props: {
    filterText: String,
    columns: Array as PropType<QTable['columns']>,
  },

  setup() {
    const { data: xodData, loading: loadingXod, search: searchXod } = useXod()
    const eventTableRef = ref<QTable | null>(null)
    const fordModal = inject<UseModal<Log[]>>('ford-modal')
    const { pagination, uniqueRoles } = useEvent()
    const { can, currentProject } = useProfile()
    const { settings } = useUserSettings()
    const project = currentProject.value?.name as string
    const { list: events, loading: loadingStateEvents } = useEvent(true)

    const {
      fetchAll: getAllVehicles,
      data: vehicles,
      loading: loadingStateVehicles,
    } = useVehicle()

    getAllVehicles()

    type SortMethod<T> = (rows: T, sortBy: string, desc: boolean) => T

    const sortMethod: SortMethod<EventTableItem[]> = (rows, sortBy, desc) => {
      const sortedRows: EventTableItem[] = orderBy(
        rows,
        [sortBy, 'created'],
        ['desc', desc ? 'desc' : 'asc', 'desc']
      )

      dateGroups.value = sortedRows.reduce<Acc>((acc, row, i) => {
        if (!(row.date in acc)) {
          acc[row.date] = i
        }

        return acc
      }, {})

      return sortedRows
    }

    const warnings = (row: EventTableItem) => {
      const _warnigns: string[] = []

      if (
        row.reason1 === 'Fordonsskada' &&
        (!row.causingVehicles?.length ||
          !row.causingVehicles?.every((x) => x.vehicleUuid))
      ) {
        _warnigns.push(`Saknar orsakande fordon`)
      }

      if (
        row.reason1 === 'Fordonsskada' &&
        (!row.causingVehicles?.length ||
          !row.causingVehicles?.every((x) => x.ford))
      ) {
        _warnigns.push(`Saknar skadenummer FORD på orsakande fordon`)
      }

      return _warnigns
    }

    const sortedEvents = computed(() => {
      const filteredEvents = events.value
        .map((event) => ({
          ...event,
          _warnings: warnings(event),
          _deviationCount:
            event.deviationCancelCount +
            event.deviationCrewCount +
            event.deviationDelayCount +
            event.deviationDisruptionCount +
            event.deviationVehicleCount,
          causingVehiclesNames: (event.causingVehicles || [])
            .map((causingVehicle) => {
              return (
                vehicles.value.find(
                  (vehicle) => vehicle.uuid === causingVehicle.vehicleUuid
                )?.name || '-'
              )
            })
            .join(', '),
        }))
        .filter((x) =>
          settings.value[project].event.roles && project === 'gotalandstag'
            ? uniqueRoles.value.length ===
                settings.value[project].event.roles.length ||
              x.createdByRoles.some((c) =>
                settings.value[project].event.roles.includes(c)
              )
            : true
        )
        .filter((x) =>
          settings.value[project].event.filterDeleted ? x?.deleted : !x?.deleted
        )
        .filter((x) =>
          settings.value[project].event.filterPenaltyAssessment
            ? x.reason1 === 'Fordonsskada' && x.penaltyAssessment === null
            : true
        )
        .filter((x) =>
          settings.value[project].event.filterAutoDelay
            ? true
            : !x.eventFlags.some((x) => x.flag === 'AUTO_DELAY')
        )
      return orderBy(filteredEvents, ['open', 'created'], ['asc', 'desc'])
    })

    type Acc = {
      [datum: string]: number
    }

    const dateGroups = ref<Acc>({})

    function onEventRowClick(e: Event, event: EventTableItem) {
      routerPush('event', { eventUuid: event.uuid })
    }

    function onClickFordChip(causingVehicle: CausingVehicle) {
      fordModal?.openModal({
        cb: async (setData) => {
          const defectNumber = causingVehicle.ford as number
          await getVehicleDefect(defectNumber)
            .then(({ data }) => {
              setData(data)
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    const loading = computed(() => {
      return (
        loadingStateVehicles.value.getAll || loadingStateEvents.value.getAll
      )
    })

    const xodColumnValue = (xodId: number) => {
      const xod = xodData.value.find((x) => xodId === x.trafficDisturbanceId)
      if (xod) {
        return xod.title
      }

      return 'Xod titel hittades inte'
    }

    function hasAutoDelay(row: { eventFlags?: { flag: string }[] }) {
      if (
        row.eventFlags?.some((x) => {
          return x.flag === 'AUTO_DELAY'
        })
      ) {
        return true
      }
      return false
    }

    return {
      can,
      sortedEvents,
      dateGroups,
      loading,
      pagination,
      sortMethod,
      onEventRowClick,
      fordModal,
      onClickFordChip,
      eventTableRef,
      searchXod,
      loadingXod,
      xodColumnValue,
      hasAutoDelay,
    }
  },
})
</script>

<style lang="scss" module>
$tableHeight: calc(100vh - 68px - 115px);

.stickyHeader {
  /* height or max-height is important */
  height: $tableHeight !important;

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    /* bg color is important for th; just specify one */
    background-color: white;
  }
  thead tr th {
    position: sticky;
    z-index: 2;
  }
  thead tr:first-child th {
    top: 0;
    z-index: 1;
  }
  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
    z-index: 3;
  }

  .q-table th {
    white-space: nowrap !important;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.eventTypeTextColor1 {
  color: #388395;
}

.eventTypeTextColor2 {
  color: #8e78c7;
}

.eventTypeTextColor3 {
  color: #68c5af;
}

.eventTypeTextColor4 {
  color: rgba(purple, 0.6);
}
</style>


import { defineComponent, computed, inject, ref, PropType, watch } from 'vue'
import { UseModal } from '@/composable/useModal'
import { Event } from '@/types/event'
import { exportExcel } from '@/services/export-excel'
import { useEvent } from '@/composable/useEvent'
import QTable from 'quasar/src/components/table/QTable.js';
import EventFilterModal from './EventFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { buildExcelSheetFromTable } from '@/common/buildExcelSheetFromTable'
import { isAfter } from 'date-fns'
import isEqual from 'lodash.isequal'
import { useUserSettings } from '@/composable/useUserSettings'

export default defineComponent({
  name: 'EventTableToolbar',

  components: {
    EventFilterModal,
  },

  emits: ['update:filterText'],

  props: {
    filterText: String,
    columns: Array as PropType<QTable['columns']>,
  },

  setup(props, { emit }) {
    const { can, currentProject } = useProfile()
    const formModal = inject<UseModal<Event>>('form-modal')
    const { period, fetchAll, loading } = useEvent()
    const filterTextValue = computed({
      get: () => props.filterText,
      set: (value) => emit('update:filterText', value),
    })
    const exporting = ref(false)

    const { settings } = useUserSettings()

    const showAllRoles = ref(true)

    const { list: events, uniqueRoles } = useEvent()
    const project = currentProject.value?.name as string
    const isFilterActiveCount = computed(() => {
      return [
        settings.value[project].event.filterPenaltyAssessment,
        settings.value[project].event.filterAutoDelay,
        settings.value[project].event.filterDeleted,
      ].filter(Boolean).length
    })

    watch(
      () => events.value,
      () => {
        uniqueRoles.value = uniqueRoles.value.map((x) => {
          return {
            visible: settings.value[project].event.roles
              ? settings.value[project].event.roles.includes(x.label)
              : true,
            label: x.label,
          }
        })
      }
    )

    watch(
      () => uniqueRoles.value,
      (list) => {
        settings.value[project].event.roles = list
          .filter((x) => x.visible)
          .map((x) => x.label)

        if (settings.value[project].event.roles.length === list.length) {
          showAllRoles.value = true
        } else {
          showAllRoles.value = false
        }
      },
      {
        deep: true,
      }
    )

    const onShowAllRoles = () => {
      showAllRoles.value = !showAllRoles.value

      uniqueRoles.value = uniqueRoles.value.map((x) => ({
        ...x,
        visible: showAllRoles.value,
      }))
    }

    const visibleRoleFilterCount = computed(() => {
      return uniqueRoles.value.filter((x) => x.visible).length
    })

    async function onExport() {
      exporting.value = true

      const exportData = buildExcelSheetFromTable({
        name: 'Händelser',
        table: events.value,
        columns: props.columns,
      })

      exportExcel(exportData)

      exporting.value = false
    }
    const showPeriodPicker = ref(false)

    const validDate = computed(() => {
      return (
        isAfter(
          new Date(tempPeriod.value.to),
          new Date(tempPeriod.value.from)
        ) ||
        isEqual(new Date(tempPeriod.value.to), new Date(tempPeriod.value.from))
      )
    })

    const tempPeriod = ref({
      from: period.value.from,
      to: period.value.to,
    })

    const fetchEvents = async () => {
      period.value.from = tempPeriod.value.from
      period.value.to = tempPeriod.value.to
      await fetchAll(period.value)
      showPeriodPicker.value = false
    }

    return {
      filterTextValue,
      formModal,
      onExport,
      exporting,
      can,
      period,
      isFilterActiveCount,
      validDate,
      fetchEvents,
      tempPeriod,
      showPeriodPicker,
      loading,
      uniqueRoles,
      visibleRoleFilterCount,
      showAllRoles,
      onShowAllRoles,
      project,
    }
  },
})


import { computed, defineComponent, provide } from 'vue'
import EventTableToolbar from '@/components/event/EventTableToolbar.vue'
import EventTable from '@/components/event/EventTable.vue'
import EventFormModal from '@/components/event/EventFormModal.vue'
import EventDeleteModal from '@/components/event/EventDeleteModal.vue'
import EventCloseModal from '@/components/event/EventCloseModal.vue'
import EventOpenModal from '@/components/event/EventOpenModal.vue'
import EventFordModal from '@/components/event/EventFordModal.vue'
import EventPenaltyAssessmentModal from '@/components/event/EventPenaltyAssessmentModal.vue'
import { useModal } from '@/composable/useModal'
import format from 'date-fns/format'
import { useEvent } from '@/composable/useEvent'
import { useProfile } from '@/composable/useProfile'
import { Event } from '@/types/event'
import { useUserSettings } from '@/composable/useUserSettings'

export default defineComponent({
  name: 'Events',

  components: {
    EventTableToolbar,
    EventTable,
    EventFormModal,
    EventDeleteModal,
    EventCloseModal,
    EventOpenModal,
    EventFordModal,
    EventPenaltyAssessmentModal,
  },

  setup() {
    const { can, currentProject } = useProfile()
    const { filterText } = useEvent()
    const { settings } = useUserSettings()

    const formModal = useModal()
    const deleteModal = useModal()
    const closeModal = useModal()
    const openModal = useModal()
    const fordModal = useModal()
    const mpkModal = useModal()
    const penaltyAssessmentModal = useModal()

    provide('form-modal', formModal)
    provide('delete-modal', deleteModal)
    provide('close-modal', closeModal)
    provide('open-modal', openModal)
    provide('ford-modal', fordModal)
    provide('delay-mpk-modal', mpkModal)
    provide('penalty-assessment-modal', penaltyAssessmentModal)
    const project = currentProject.value?.name as string
    const autoDelayRow = (row: Event, show: boolean) =>
      show
        ? row.eventFlags.some((x) => x.flag === 'AUTO_DELAY') && 'autoDelayRow'
        : ''

    const columns = computed(() => {
      return [
        {
          label: '',
          field: 'actions',
          required: true,
          align: 'right ',
          name: 'actions',
          sortable: false,
          style: 'width: 78px; min-width: 78px; max-width: 78px',
        },
        {
          align: 'left',
          name: '_event_type',
          label: 'Avvikelser',
          field: '_event_type',
          required: true,
          sortable: false,
          style: 'width: 100px; min-width: 100px; max-width: 100px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          align: 'left',
          name: 'start',
          label: 'Datum',
          field: 'date',
          required: true,
          sortable: false,
          style: 'width: 100px; min-width: 100px; max-width: 100px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          align: 'left',
          name: '_warnings',
          required: true,
          label: 'Varningar',
          field: '_warnings',
          format: (v: boolean) => (v ? 'Ja' : ''),
          sortable: true,
          style: 'width: 70px; min-width: 70px; max-width: 70px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          align: 'left',
          name: 'open',
          required: true,
          label: 'Öppen',
          field: 'open',
          format: (v: boolean) => (v ? 'Ja' : ''),
          sortable: true,
          style: 'width: 70px; min-width: 70px; max-width: 70px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          align: 'left',
          name: 'id',
          required: true,
          label: 'Id',
          field: 'id',
          sortable: true,
          style: 'width: 45px; min-width: 45px; max-width: 45px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          align: 'left',
          name: 'penalty',
          required: true,
          label: 'Vitesbelag',
          format: (v: boolean) => (v ? 'Ja' : 'Nej'),
          field: 'penalty',
          sortable: true,
          style: 'width: 100px; min-width: 100px; max-width: 100px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          align: 'left',
          name: 'description',
          required: true,
          label: 'Beskrivning',
          field: 'description',
          sortable: true,
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'Orsak 1',
          field: 'reason1',
          required: true,
          align: 'left',
          style: 'width: 120px; min-width: 120px; max-width: 120px',
          sortable: true,
          name: 'reason1',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'Orsak 2',
          field: 'reason2',
          style: 'width: 120px; min-width: 120px; max-width: 120px',
          required: true,
          align: 'left',
          sortable: true,
          name: 'reason2',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'Orsak 3',
          field: 'reason3',
          style: 'width: 120px; min-width: 120px; max-width: 120px',
          required: true,
          align: 'left',
          sortable: true,
          name: 'reason3',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'Åtgärd',
          field: 'errorRemedy',
          required: true,
          align: 'left',
          sortable: true,
          name: 'errorRemedy',
          style: 'width: 150px; min-width: 150px; max-width: 150px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'Orsakande fordon',
          field: 'causingVehiclesNames',
          required: true,
          align: 'left',
          sortable: true,
          name: 'causingVehiclesNames',
          style: 'width: 130px; min-width: 130px; max-width: 130px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'FORD skadenr.',
          field: 'causingVehicles',
          required: true,
          align: 'left',
          sortable: true,
          name: 'causingVehicles',
          style: 'width: 120px; min-width: 120px; max-width: 120px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'XOD Id',
          field: 'xod',
          required: true,
          align: 'left',
          sortable: true,
          name: 'xod',
          style: 'width: 98px; min-width: 98px; max-width: 98px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'OEU',
          field: 'penaltyAssessment',
          required: true,
          align: 'left',
          sortable: true,
          name: 'penaltyAssessment',
          format: (v: null | boolean, row: { reason1: string }) => {
            if (row.reason1 === 'Fordonsskada')
              return v === null ? 'Saknas' : v ? 'Ja' : 'Nej'
            return ''
          },
          permission: 'event.penaltyAssessment',
          style: 'width: 60px; min-width: 60px; max-width: 60px',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        // {
        //   label: 'Avvikelser',
        //   field: '_deviationCount',
        //   required: true,
        //   align: 'left',
        //   sortable: true,
        //   name: '_deviationCount',
        //   style: 'width: 98px; min-width: 98px; max-width: 98px',
        // },
        {
          label: 'Skapad av',
          field: 'createdBy',
          required: true,
          align: 'left',
          sortable: true,
          name: 'createdBy',
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
        {
          label: 'Skapad',
          field: 'created',
          style: 'width: 118px; min-width: 118px; max-width: 118px',
          required: true,
          align: 'left',
          sortable: true,
          name: 'created',
          format: (v: Date) =>
            v ? format(new Date(v), 'yyyy-MM-dd HH:mm') : null,
          classes: (row: Event) =>
            autoDelayRow(row, settings.value[project].event.filterAutoDelay),
        },
      ].filter((x) => {
        if (!x.permission) return true
        return can(x.permission)
      })
    })

    return {
      filterText,
      formModal,
      deleteModal,
      closeModal,
      openModal,
      columns,
      fordModal,
      penaltyAssessmentModal,
    }
  },
})

<template>
  <q-menu style="width: 444px" v-model="showFilterMenu">
    <div class="row q-pa-md">
      <div class="row">
        <div class="col">
          <q-toggle
            v-model="settings[project].event.filterPenaltyAssessment"
            label="Visa händelser med vitesbedömning saknas"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <q-toggle
            v-model="settings[project].event.filterAutoDelay"
            label="Visa automatiskt skapade händelser"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <q-toggle
            v-model="settings[project].event.filterDeleted"
            label="Visa endast borttagna händelser"
          />
        </div>
      </div>
    </div>
  </q-menu>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useEvent } from '@/composable/useEvent'
import { QForm } from 'quasar'
import { isAfter, isEqual } from 'date-fns'
import { useUserSettings } from '@/composable/useUserSettings'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'EventFilterModal',

  components: {},

  setup() {
    const formRef = ref<QForm | null>(null)
    const { period, loading } = useEvent()
    const { currentProject } = useProfile()
    const project = currentProject.value?.name as string
    const { settings } = useUserSettings()

    const showFilterMenu = ref(false)

    const validDate = computed(() => {
      return (
        isAfter(new Date(period.value.to), new Date(period.value.from)) ||
        isEqual(new Date(period.value.to), new Date(period.value.from))
      )
    })

    return {
      showFilterMenu,
      loading,
      validDate,
      formRef,
      period,
      settings,
      project,
    }
  },
})
</script>
